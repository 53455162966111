/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery-match-height@0.7.2/dist/jquery.matchHeight.min.js
 * - /npm/owl.carousel@2.3.4/dist/owl.carousel.min.js
 * - /npm/wowjs@1.1.3/dist/wow.min.js
 * - /npm/bootstrap@3.4.1/dist/js/bootstrap.min.js
 * - /npm/sweetalert2@8.19.1/dist/sweetalert2.all.min.js
 * - /npm/slick-carousel@1.8.1/slick/slick.min.js
 * - /npm/select2@4.0.6-rc.1/dist/js/select2.full.min.js
 * - /npm/components-jqueryui@1.12.1/jquery-ui.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
